<template>
<div class="main">
      <nav class="navbar navbar-light">
        <div class="container-fluid col-12">
            <div class="navbar-brand"><img src="@/assets/logo.png" alt="HOME" title="HOME"></div>
            
              <div class="d-flex my-2 mx-2 my-lg-0">
                <div class="dropdown">
                   <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">
                    <span class="control">
                        <a class="button">
                         <b-icon icon="person-fill"> </b-icon>
                          {{ init.profile.fullName }}
                        </a>   
                    </span><span class="caret"></span></button>
                   <div class="dropdown-content">
                        <router-link :to="'/txhistory/' + userId"><div class="a-text">Transaction History </div></router-link>
                        <a href="#" class="a-text" v-on:click="openLogout">Log out </a>
                    </div>
                </div>
              </div>
          </div>
          </nav>
    <div >
      <h1 class="mt-5 text-white">Pending</h1>
      <div class="container mx-auto col-md-8 my-5 successful">
        <div class="row">
          <div class="col-md-5">
            <b-card>
              <div class="card-body">
                <h5 class="card-title text-uppercase mb-3">Total summary</h5>
                <div class="d-flex justify-content-between">
                  <div>
                    <img
                      :src="image"
                      class="store-img"
                    />
                    <h6 for="product-tag">{{crypto}}</h6>
                  </div>
                  <h4 for="product-price">{{cryptoAmount}}</h4>
                </div>
    
                <hr />
                <h3>Total Amount Due:</h3>

                <div class="d-flex justify-content-between">
                  <div>
                   <img
                      class="store-img"
                    />
                    <h2 for="product-tag">{{fiat}}</h2>
                  </div>
                  <h4 class="pt-5" for="product-price">{{formatAmount(fiatAmount)}}</h4>
                  
                </div>
                <label for="timestamp">Date and time:</label>
                    <p>{{formatDate(txnDate)}}</p>
                <label for="walletAddress">Wallet Address:</label>
                    <p>{{walletAddress}}</p>
                    
                    <label for="paymentMethod">Payment Method:</label>
                    <p>{{paymentMethod}}</p>
                    <label for="reference">Reference Number:</label>
                    <p>{{referenceId}}</p>
                    <label for="status">Status:</label>
                    <p>{{paymentStatus}}</p>
                
              </div>
            </b-card>
          </div>
          <div class="col-md-7">
            <b-card>
              <div class="card-body text-dark">
                <h5 class="card-title text-uppercase mb-2">Banking details</h5>
                <div class="col-12 d-flex justify-content-between my-3 text-left">
                  <div class="col-12">
                    <label for="details">Bank Name:</label>
                    <p>Malayan Savings Bank</p>
                    
                    <label for="details">Account Name:</label>
                    <p>Frenetic, Inc.</p>
                    <label for="details">Branch:</label>
                    <p>15L 15 Parkview 2 Orchard RD.Buhay, Quezon City, PH, 1109</p>
                    <label for="details">Account Number:</label>
                    <p>001 29-00012-1</p>
                  </div>
                  
                </div>

                <b-card class="py-4">
                  <p>Once done, kindly send us a copy of your bank transfer receipt or proof of payment at <a href="mailto:store@frenetic.ph" class="text-dark">store@frenetic.ph</a> within 10 minutes to complete your transaction. Kindly indicate your Reference Number as the subject.</p>
                </b-card>
                <b-button
                  class="mt-5 btn btn-primary text-uppercase btn-block"
                  @click="done()">
                  done
                </b-button>
              </div>
            </b-card>
            
          </div>
        </div>
      <LogoutModal @close="openLogout" v-if="showLogoutModal"/>
      </div>
    </div>
    </div>
    </template>
    
    <script>
    
    import numeral from 'numeral'
    import LogoutModal from './LogoutModal.vue'
    
    export default {
      name: "Bank",
      props: ["txnId"],
      components: {
       LogoutModal
      },
      data() {
        return {
         crypto: '',
         paymentMethod: '',
         txnDate:'',
         paymentStatus:'',
         fiat:'',
         cryptoAmount:'',
         fiatAmount:'',
         walletAddress:'',
         comment:'',
         referenceId:'',
         image:'',
         showLogoutModal: false,
         lockedPrice: '',
         userId: ''

          
    
    
        };
      },
      computed: {
        product() {
          return this.$store.state.product;
        },
        cart() {
          return this.$store.state.cart;
        },
        checkout() {
          return this.$store.state.checkout;
        },
        init() {
          return this.$store.state.init;
        },
      },
      
      methods: {
       openLogout(){
          this.showLogoutModal = !this.showLogoutModal;
        },
        getProfile: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/cx/profile/',
          };

          this.axios(options)
            .then((response) => {
               
               this.init.profile = response.data;

              })
      },
     checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
           .then((response) => { 
            this.getProfile();
              setTimeout( () => {
                this.showLoading = false;
              },1000)
            }).catch((err) => {
                
              if(err.response.data.msgCode == '00001') {
                window.location.href = "/#/";
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                window.location.href = "/#/setup-profile";
               },1000)
                
              }  

            })
      },
      
        logout: function(){
            this.axios.get('/cx/logout')
            .then((response) => { 
              
              window.location.href = "/#/";
                
            }).catch((err) => { 
              console.log(err)

            })
        },
        
       
        formatAmount: function(amount) {
                return numeral(amount).format('0,0.000000') 
        },
        formatDate: function(time){
            return new Date().toDateString();
        },

        getTxnDetails(){
          console.log (localStorage.getItem("txnId"))
          const params = {
               txnId: localStorage.getItem("txnId")
             }
             const options = {
              method: 'POST',
              headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
              params,
              url: '/cx/txn/buycrypto/details',
            };

                this.axios(options)
                  .then((response) => {
                    console.log(response.data)
                    this.userId = response.data.entityId
                    this.lockedPrice = response.data.lockedPrice
                    this.crypto = response.data.targetCurrency
                    this.paymentMethod = response.data.paymentMethod
                    this.paymentDate = response.data.paymentDate
                    this.paymentStatus = response.data.paymentStatus
                    this.fiat = response.data.sourceCurrency
                    this.cryptoAmount = response.data.targetAmount
                    this.fiatAmount = response.data.sourceAmount
                    this.walletAddress = response.data.walletAddress
                    this.comment = response.data.txnComment
                    this.referenceId = response.data.txnId
                    this.image = '../assets/crypto/' + this.crypto + '.png'

                    })
            },
            done(){
              this.$router.push({name: 'TransactionHistory', params: { userId:this.userId}})
              
            }
        
      },
     
       mounted(){
         this.getTxnDetails();
      },
      beforeMount(){
            this.checkSession();
            
          },
    };
    </script>
    <style scoped>
    .bg, .main{
      background: rgb(2,11,54);
      width: 100%;
      height: auto !important;
    }
    .navbar{
      padding: 10px 100px;
    }
    .successful{
        margin: 0 auto;
        height: auto;
        background-image: linear-gradient(
        180deg,
        rgba(249, 250, 253, 1) 29%,
        rgba(235, 238, 249, 1) 59%,
        rgba(214, 220, 244, 1) 88%
        );
    }
    label{
      padding: 0 !important;
      margin: 0 !important;
      font-size: 20px;
    }
    .card-body{
      font-size: 25px;
    }
    .store-img{
      width: 7rem;
    }
    a .text-dark{
      text-decoration: none !important;
    }
     @media only screen and (max-device-width: 480px) {
        .main{
         scroll-behavior: auto !important;
         height: 100vh !important;
         overflow-y: auto;
         overflow-x: hidden
       }
       .body{
        margin-bottom: 200px !important;
        padding-bottom: 40px;
        }
       .store-img{
          width: 3rem;
        }
        .text-center, .cart-text, .input-text, h4, h6, h2 {
          font-size: 20px;
          padding: 0 !important;
          margin: 0 !important;
        }

        .card-body{
          padding: 10px !important;
          margin: 0 !important;
        }
        .card-title{
          padding-top: 10px;
        }
        .text-muted{
          font-size: 10px;
          word-break: break-all !important;
          padding: 0 !important;
          margin: 0 !important;
        }
        label{
          font-size: 10px;
          padding: 0 !important;
          margin: 0 !important;
          
        }
        .form-control{
          padding: 0 !important;
          margin: 0 !important;
        }
        form , .payment-container{
          display: block !important;
        }
        .payment-container h5{
          font-size: 15px;
        }
        .checkout{
          height: auto;
        }
        .d-flex{
          margin: 0 !important;
          padding: 0px 10px !important;
        }
    }
     @media only screen and (max-device-width: 600px) {
       .main{
         scroll-behavior: auto !important;
         height: 100vh !important;
         overflow-y: auto;
         overflow-x: hidden
       }
       .body{
        margin-bottom: 200px !important;
        padding-bottom: 40px;
        }
       .store-img{
          width: 3rem;
        }
        form .text-center, .cart-text, .input-text {
          font-size: 20px;
          padding: 0 !important;
          margin: 0 !important;
        }
        .card-body{
          padding: 0 !important;
          margin: 0 !important;
        }
        .text-muted{
          font-size: 10px;
          word-break: break-all !important;
          padding: 0 !important;
          margin: 0 !important;
        }
        label{
          font-size: 10px;
          padding: 0 !important;
          margin: 0 !important;
          
        }
        .form-control{
          padding: 0 !important;
          margin: 0 !important;
        }
        form , .payment-container{
          display: block !important;
        }
        .payment-container h5{
          font-size: 15px;
        }
        .checkout{
          height: auto;
        }
     }
       @media (min-width: 600px) and (max-width: 1024px) {
         .successful, .cardContainer{
            margin: 0 auto;
            height: auto !important;
            width: 100%;
        }
        .main{
          scroll-behavior: auto !important;
          height: 100vh !important;
          overflow-y: auto;
          overflow-x: hidden
        }
       .body{
          margin-bottom: 200px !important;
          padding-bottom: 40px;
        }
        form{
          padding: 0 !important;
          width: auto;
        }
        .payment-container{
          margin: 0 auto !important;
          padding: 0 auto !important;
        }
      
      }
      

    </style>
    