<template>
   <div class="main">
     <nav class="navbar navbar-light">
        <div class="container-fluid col-12">
            <div class="navbar-brand"><img src="@/assets/logo.png" alt="HOME" title="HOME"></div>
            
              <div class="d-flex my-2 mx-2 my-lg-0">
                <div class="dropdown">
                   <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">
                    <span class="control">
                        <a class="button">
                         <b-icon icon="person-fill"> </b-icon>
                          {{ init.profile.fullName }}
                        </a>   
                    </span><span class="caret"></span></button>
                   <div class="dropdown-content">
                        <router-link :to="'/txhistory/' + userId"><div class="a-text">Transaction History </div></router-link>
                        <a href="#" class="a-text" v-on:click="openLogout">Log out </a>
                    </div>
                </div>
              </div>
          </div>
          </nav>

      <div class="body">

      <h1 class="mt-5 text-white">Checkout</h1>
      <div class="container col-md-8 my-5 checkout">
       <div class="d-modal row">
            <div class="cart-header modal-header">
              <div class="cart-title modal-title">
              <!-- <Timer/> -->
              </div>
            </div>
        <form v-on:submit.prevent="proceedToPayment()">
          <div class="row cardContainer">
             <div class="col-md-6 pt-3">
              <div>
                <div v-if="cart">
                    <div
                      class="card-title d-flex justify-content-between text-uppercase"
                    >
                      <h5>DETAILS:</h5>
                      
                    </div>
                    <div class="d-flex justify-content-between">
                      
                      <h5>You bought: </h5>
                       
                      <h5>{{cryptoBought}}  {{cart.fromCurrency}} <img
                        :src="cart.image"
                          class="store-img"
                        /></h5>
                    </div>
                    <div class="d-flex justify-content-between mb-3">
                      <label for="lockedPrice">Locked Price</label>
                      <h5 class="card-text text-muted">
                      {{formatAmount(cart.lockedPrice)}}
                      </h5>
                    </div>
                    <hr />
                    <div class="card-footer mb-3">
                      <div class="d-flex justify-content-between">
                        <h6 for="order">You will receive in <span class="text-small"> {{cart.fromCurrency}}</span></h6>
                        <h6>{{cryptoReceive}}</h6>
                      </div>
                      
                      <div class="d-flex justify-content-between mb-2">
                        <h6 id="currency">You will pay in <span class="text-small">{{cart.toCurrency}}</span></h6>
                        <h6 id="totalAmount">{{ formatAmount(amountDue)}}</h6>
                      </div>
                    </div>
                  </div>
                
              </div>
            </div>
            <div class="col-md-6">
              <div class="card cardForm1">
                <div class="card-body">
                  <h5 class="card-title text-uppercase">enter wallet information</h5>
                  <form class="pt-2">
                    <label for="walletAddress">Wallet Address:
                    <span class="text-muted"> ***Please make sure you enter the correct wallet address.</span></label>
                    <input
                      type="text"
                      class="form-control"
                      v-model.trim.lazy="walletAddress"
                      placeholder="Enter your wallet address."
                      required
                    />
                    <p class="error-text">{{error.walletAddress}}</p>
                    <label for="message">Comment:</label>
                    <textarea
                      type="text"
                      class="form-control"
                      v-model.lazy="comment"
                      placeholder="special instruction"
                    ></textarea>
                  </form>
                </div>
              </div>
            </div>
           
    
            <div class="row container">
              <h4 class="text-uppercase d-flex text-dark text-left">
                payment method
              </h4>
              <p class="error-text">{{error.msg}}</p>
              <div class="payment-container text-left">
                <form class="d-flex justify-content-between">
                  <h5 class="p-3">Choose Method: </h5>
    
                  <div class="form-check px-5">
                    <input
                      class="form-check-input"
                      v-model="payment"
                      type="radio"
                      value="ccard"
                      disabled
                    />
                    <label class="form-check-label" for="ccard">
                      Credit card
                    </label>
                  </div>
    
                  <div class="form-check px-5">
                    <input
                      @change="onChange($event)"
                      class="form-check-input"
                      v-model="payment"
                      type="radio"
                      value="BANK"
                      disabled
                      
                    />
                    <label class="form-check-label" for="BANK">
                      Online Banking
                    </label>
                  </div>
                  <div class="form-check px-5">
                    <input
                    @change="onChange($event)"
                      class="form-check-input"
                      v-model="payment"
                      type="radio"
                      value="GCASH"
                      disabled
                    />
                    <label class="form-check-label" for="GCASH"> GCash </label>
                  </div>
                  <!-- <div class="form-check px-5">
                    <input
                    @change="onChange($event)"
                      class="form-check-input"
                      v-model="payment"
                      type="radio"
                      value="JOJOPAY"
                      disabled
                    />
                    <label class="form-check-label" for="JOJOPAY"> JoJoPay </label>
                  </div> -->
                </form>
              </div>
    
              <div class="d-flex justify-content-between">
                <div class="form-check px-5 pt-3">
                  <input
                    class="form-check-input"
                    v-model="terms"
                    required
                    type="checkbox"
                    id="terms"
                  />
                  <label class="form-check-label" for="terms">
                    I have read and agreed to the website's
                    <a href="#" class="text-dark"> Terms and Conditions*</a>
                  </label>
                </div>
                <div class="pt-3">
                  <button @click="exitModal" class="btn btn-dark mb-2 mx-2">Back</button>
                  <button class="btn btn-primary mb-2" type="submit">
                    Proceed
                  </button>
                </div>
              </div>
            </div>
            </div>
          
        </form>
       </div>
      </div>
      
    </div>
      <Exit @close="exitModal" v-if="showExitModal"/>
      <Successful :txnId="txnId" v-if="state == 'SUCCESS'"/>
      <Failed :txnId="txnId" v-if="state == 'REJECT'"/>
      <Bank :txnId="txnId" v-if="state == 'PENDING'"/>
      <PriceReset v-if="lockedPriceReset"/>
      <LogoutModal @close="openLogout" v-if="showLogoutModal"/>
      <Footer/>
    </div>
    </template>
    
    
    <script>
    import Footer from '../views/Footer.vue'
    import numeral from 'numeral'
    import Exit from '../components/modals/Exit.vue'
    import Successful from '../components/Successful.vue'
    import Failed from '../components/modals/Failed.vue'
    import Bank from '../components/modals/Bank.vue' 
    import PriceReset from '../components/modals/PriceReset.vue'
    import LogoutModal from '../components/modals/LogoutModal.vue'
    import Timer from "../components/modals/Timer.vue"
    
    export default {
      components: { Exit, Successful, Failed, Bank, PriceReset, LogoutModal, Footer, Timer},
      name: "Checkout",
      data() {
        return {
          timestamp: '',
          walletAddress: '',
          comment: '',
          payment: '',
          trxId: '',
          terms: '',
          cartId: '',
          referenceId: '',
          showExitModal: false,
          cryptoBought:'',
          cryptoReceive:'',
          showExitModal: false,
          sourceCurrency: 'PHP',
          txnControlId:'',
          amountDue:'',
          txnId:'',
          confirmResponses: {},
          state: '',
          error:{
            show: false,
            walletAddress:'',
            msg:''

          },
          isEditing: false,
          lockedPriceReset: false,
          showLogoutModal: false,

        };
      },
      computed: {
        cartItems(){
          return this.$store.state.cartItems;
        },
        cart() {
          return this.$store.state.cart;
        },
        checkout(){
          return this.$store.state.checkout;
        },
        init() {
          return this.$store.state.init;
        },
      
        
      },
      
      created: function(){
        let loaded = JSON.parse(localStorage.getItem('cartItems'));
    
        if(loaded) {
          this.cart.product = loaded.product;
          this.cart.fromCurrency = loaded.fromCurrency;
          this.cart.toCurrency = loaded.toCurrency;
          this.cart.image = loaded.image;
          this.userId = loaded.userId;
          this.cart.lockedPrice = loaded.price;
          this.cart.fee = loaded.fee;
          this.cryptoReceive = loaded.cryptoToReceive;
          this.amountDue = loaded.totalFiat;
          this.cryptoBought = loaded.cryptoInput;
          this.cartTimer = localStorage.getItem("timer")
          }
          console.log(localStorage.getItem('cartItems'))
    
    
      },
      methods: {
        openLogout(){
          this.showLogoutModal = !this.showLogoutModal;
        },
         exitModal() {
              this.showExitModal = !this.showExitModal;
          },
          onChange(event){
            const selectedPayment = event.target.value;
            this.selectedPayment = selectedPayment
            console.log(this.selectedPayment)
          },
          clearError(){
            this.error.walletAddress = ""
            this.error.msg = ""
          },

          initCheckout(){
            const options = {
              method: 'POST',
              headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
              url: '/cx/txn/buycrypto/init',
            };

                this.axios(options)
                  .then((response) => {
                    
                    this.txnControlId = response.data.txnControlId
                    console.log(this.txnControlId)
                    
                    })
            },
            proceedToPayment(){
              let hasError = false;

               if(this.walletAddress == '' ) {
                   this.error.walletAddress = "This is required."
                   hasError = true
                    setTimeout(()=>{
                      this.clearError()
                    },4000);
                 }

              if(!hasError ){
                const params = {
                  paymentMethod: this.selectedPayment,
                  sourceAmount: this.amountDue,
                  sourceCurrency: this.cart.fromCurrency,
                  targetCurrency: this.cart.toCurrency,
                  lockedPrice: this.cart.lockedPrice,
                  walletAddress: this.walletAddress,
                  txnComment: this.comment,
                  txnControlId: this.txnControlId
                }
                localStorage.setItem('checkoutDetails', JSON.stringify(params));
                    console.log(params);
                    

                const options = {
                method: 'POST',
                  headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                  params,
                  url: '/cx/txn/buycrypto/submit',
                };

                this.axios(options)
                .then((response) => {
                  console.log(response.data)
                  this.error.walletAddress = ""
                    
                     this.onConfirm();
                  
                  
                }).catch((error) => {
                  console.log(error.response.data)
                  this.error.walletAddress = ""
                       if (error.response.data) { 
                            this.error.show = true;
                            if(error.response.data.fieldErrors.length > 0) {
                                error.response.data.fieldErrors.forEach((error) => {
                                switch (error.field) {
                                    default:
                                    this.error[error.field] = error.defaultMessage;
                                    break;
                                }
                                }); 
                                this.error.msg = error.response.data.fieldErrors[0].defaultMessage;

                            }  else {
                                this.error.msg = error.response.data.msgText;
                            }
                        } 
                  

                  setTimeout(()=>{
                    this.clearError()
                  },1000);

                })
            }

          },
          onConfirm(){
             const params = {
               txnControlId: this.txnControlId
             }
             const options = {
              method: 'POST',
              headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
              params,
              url: '/cx/txn/buycrypto/confirm',
            };

                this.axios(options)
                  .then((response) => {
                    localStorage.setItem("txnId", response.data.txnId)
                    this.txnId = localStorage.getItem("txnId")
                    this.txnId = response.data.txnId
                    this.state = response.data.paymentStatus
                    this.paymentMethod = response.data.paymentMethod
                   
                    console.log(response.data)
                      if(response.data.paymentMethod == 'BANK'){
                        console.log("bank")
                      window.location.href = '/#/banking/' + this.txnId
                      } else {
                            window.location.href = (response.data.redirectUrl)
                      }

                       history.pushState(null, null, location.href);
                        window.onpopstate = function () {
                            history.go(1);
                        };
                    })
            },
            getProfile: function(){
              const options = {
                  method: 'GET',
                  headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                  url: '/cx/profile/',
                };

                this.axios(options)
                  .then((response) => {
                    
                    this.init.profile = response.data;
                    console.log(response.data)

                    })
            },

             checkSession: function() {

              const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                url: '/cx/sessioncheck',
              };

              this.axios(options)
                .then((response) => { 
                  this.getProfile();
                    setTimeout( () => {
                      this.showLoading = false;
                    },1000)
                  }).catch((err) => {
                      
                    if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/";
                    }  

                    if(err.response.data.msgCode == '00020') {

                      setTimeout( () => {
                      window.location.href = "/#/setup-profile";
                    },1000)
                      
                    }  

                  })
            },
          
            logout: function(){
                this.axios.get('/cx/logout')
                .then((response) => { 
                  
                  window.location.href = "/#/";
                    
                }).catch((err) => { 
                  console.log(err)

                })
            },
             
       
        formatAmount: function(amount) {
                return numeral(amount).format('0,0.00') 
          },
         preventNav(event) {
                if (!this.isEditing) return
                event.preventDefault()
                event.returnValue = ""
          },
       
        
        
      },
      beforeMount(){
          this.checkSession();
          window.addEventListener("beforeunload", this.preventNav)
        },
      beforeDestroy() {
        window.removeEventListener("beforeunload", this.preventNav);
      },

      beforeRouteLeave(to, from, next) {
        if (this.isEditing) {
          if (!window.confirm("Leave without saving?")) {
              return;
           }
        }
        next();
      },
          
      
      mounted(){
          this.initCheckout();
        
      },
     
    };
    </script>
    <style scoped>
      .main{
        height: 100vh !important;
        overflow-y: auto;
      }
      .body{
        margin-bottom: 100px !important;
        padding-bottom: 20px;
      }
     
      .navbar{
        display: flex;
        padding: 20px 100px;
      }
      .text-muted{
        font-size: 15px;
      }
      .error-text{
        font-size: 25px;
        color: red;
      }
    .checkout{
      font-size: 2rem;
      }
      label{
        font-size: 1rem;
      }
      .cardContainer {
        margin: 0 auto;
        background-image: linear-gradient(
          180deg,
          rgba(249, 250, 253, 1) 29%,
          rgba(235, 238, 249, 1) 59%,
          rgba(214, 220, 244, 1) 88%
      );
        width: auto;
        max-width: auto;
        height: auto;
        border: 10px;
        padding: 20px;
      }
      .cardForm1 {
        border: none;
      }
    .payment-container{
      display: flex;
      justify-content: center;
    }
    .cardForm2 {
      border: none;
      background: rgb(240, 240, 240);
      padding: 20px;
    }
    .collapseContent {
      padding: 0 18px;
      display: none;
      overflow: hidden;
      background-color: #f1f1f1;
    }
     @media only screen and (max-device-width: 600px) {
       .main{
         scroll-behavior: auto !important;
         height: 100vh !important;
         overflow-y: auto;
         overflow-x: hidden
       }
       .body{
        margin-bottom: 200px !important;
        padding-bottom: 40px;
        }
       .store-img{
          width: 3rem;
        }
        form .text-center, .cart-text, .input-text {
          font-size: 20px;
          padding: 0 !important;
          margin: 0 !important;
        }
        .card-body{
          padding: 0 !important;
          margin: 0 !important;
        }
        .text-muted{
          font-size: 10px;
          word-break: break-all !important;
          padding: 0 !important;
          margin: 0 !important;
        }
        label{
          font-size: 10px;
          padding: 0 !important;
          margin: 0 !important;
          
        }
        .form-control{
          padding: 0 !important;
          margin: 0 !important;
        }
        form , .payment-container{
          display: block !important;
        }
        .payment-container h5{
          font-size: 15px;
        }
        .checkout{
          height: auto;
        }
     }
       @media (min-width: 600px) and (max-width: 1024px) {
         .checkout, .cardContainer{
            margin: 0 auto;
            height: auto !important;
            width: 100%;
        }
        .main{
          scroll-behavior: auto !important;
          height: 100vh !important;
          overflow-y: auto;
          overflow-x: hidden
        }
       .body{
          margin-bottom: 200px !important;
          padding-bottom: 40px;
        }
        form{
          padding: 0 !important;
          width: auto;
        }
        .payment-container{
          margin: 0 auto !important;
          padding: 0 auto !important;
        }
      
      }
      
    </style>
    